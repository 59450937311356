.header {
    background: #FFD52F;
    border-top: 1px solid #FFD52F;
    border-bottom: 1px solid #FFD52F;
    padding: 30px 50px;
    @media (max-width: 700px) {
        padding: 30px 20px;
    }
}

.header_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.back {
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    svg{
        width: 13px;
        display: block;
        transform: rotate(180deg);
    }
    span {
        text-decoration: underline;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        color: #000000;
    }
}

.step {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
}

.columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
    }
}

.column{
    @media (max-width: 700px) {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
}

.title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    margin: 0px;
}

.type_excursion {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    display: block;
}

.location {
    display: block;
    margin: 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #000000;

    svg {
        margin-right: 10px;
    }

    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 20px;
    }
}

.select_day {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    text-transform: capitalize;
    margin-bottom: 10px;
    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 20px;
    }
}

.time_slot {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 700px) {
        font-size: 18px;
        line-height: 20px;
    }
}