.container {
    // padding: 20px 40px;
    //height: 100%;
    //min-height: 100%;
}

.header {
    padding: 20px 40px 0px 40px;
    @media (max-width: 700px) {
        padding: 20px 10px 0px 15px;
    }
}

.header_slots{
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    @media (max-width: 700px) {
        flex-direction: column;
    }
}

.content {
    padding: 0px 40px 20px 40px;
    @media (max-width: 700px) {
        padding: 0px 10px 20px 15px;
    }
}

.location {
    font: normal normal bold 14px/18px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    align-items: center;

    img {
        margin-right: 8px;
    }
}

.columns {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 45px;
    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
}

.container_slots {
    overflow-y: scroll;
    height: 100%;
    padding-top: 10px;
    width: 100%;
    max-height: 425px;
    @media (max-width: 700px) {
        max-height: unset;
    }
}