.item{
    background: #efefef;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
}