.form {
    display: block;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 700px) {
        flex-direction: column-reverse;
    }
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 700px) {
        flex-direction: column;
        gap: 20px;
    }
}

.accept {
    grid-column: -1/1;
    display: flex;
    flex-direction: row;

    label {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }
}

.footer {
    grid-column: -1/1;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.price {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    display: block;
    text-align: center;

    span {
        font-weight: 800;
    }

}