.slot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 0px 10px #00000017;
    border-radius: 3px;
    padding: 8px 11px;
    margin-bottom: 5px;
    min-height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 700px) {
        //margin-left: 0px;
        //margin-right: 0px;
    }
}

.time {
    text-align: left;
    font: normal normal normal 15px/19px Montserrat;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    align-items: center;

    svg {
        display: block;
        margin-right: 10px;
    }

}

.buy {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
}

.payment {
    display: flex;
    flex-direction: column;
}

.price {
    text-align: right;
    font: normal normal bold 15px/1 Montserrat;
    letter-spacing: 0px;
    color: #000000;
    @media (max-width: 700px) {
        font-size: 14px;
    }
}

.price_description {
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0px;
    color: #676767;
}

.button {
    text-align: center;
    font: normal normal bold 15px/19px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    padding: 6px;
    min-height: unset;
    margin: 0px;
    @media (max-width: 700px) {
        min-width: unset;
        font-size: 14px;
    }
}