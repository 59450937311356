.calendar {
    max-width: 375px;
    width: 100%;
    @media (max-width: 700px) {
        margin-left: auto;
        margin-right: auto;
    }
}

.week_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container_input {
    position: relative;
}

.week_label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    width: calc(100% - 20px);
    text-align: center;
    white-space: nowrap;
}

.week,
.week[type="month"] {
    all: unset;
    margin: 0px;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: rgba(#000, 0.0);
    text-transform: capitalize;
    text-align: center;
}

.week_button {
    all: unset;
    width: 25px;
    height: 25px;
    background: #D2DFFF;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);
    gap: 10px;

}

.dayWeek {
    margin-top: 10px;
    width: 35px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}

.day {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ECECEC;
    border-radius: 3px;
    text-align: center;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    user-select: none;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
        background: rgba(#FFD52F, 0.5);
        border-color: #FFD52F;
    }

    @media (max-width: 700px) {
        width: 35px;
        height: 35px;
    }
}

.last_day {
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #B5B5B5;
    cursor: default;

    &:hover {
        background: unset;
        border-color: #ECECEC;
    }
}

.current_day {
    background: #D2DFFF;
    border-color: #D2DFFF;

}

.checked_day {
    background: #FFD52F;
    border-color: #FFD52F;

    &:hover {
        background: #FFD52F;
        border-color: #FFD52F;
    }
}
