.form {
    display: grid;
    grid-template-columns: 1fr 1fr;

    select,
    input {
        margin-top: 0px;
        margin-bottom: 10px;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    input[type="number"] {
        padding-right: 100px;
    }

    h3 {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
}

.accept {
    grid-column: -1/1;
    display: flex;
    flex-direction: row;

    label {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }
}

.footer {
    grid-column: -1/1;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.price {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    display: block;
    text-align: center;

    span {
        font-weight: 800;
    }
}

.confirm {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}