.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 650px;
    padding: 20px 40px;

}

.data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 700px;
    p {
        text-align: left;
        font: normal normal 600 16px/19px Montserrat;
        letter-spacing: 0px;
        color: #000000;
    }
}

.sum {
    text-align: left;
    font: normal normal 600 20px/24px Montserrat !important;
    letter-spacing: 0px;
    color: #000000;
}

.price {
    text-align: left;
    font: normal normal bold 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}

.title {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 40px;
}

.description {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 35px;
}

.thank{
    margin-top: 30px;
}
.link {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 345px;
    width: 100%;
}