.form {
    display: block;

    input {
        margin-bottom: 0px;
    }

    //label.error{
    //    border-color: #ff5c5c;
    //}

}

.personal_information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 15px;

    h3 {
        grid-column: -1/1;
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
}

.additional_information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
}

.confirmation_method {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 10px;

    label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px;
        border: 1px solid #ECECEC;
        border-radius: 3px;
        margin: 0px !important;
    }

    input[type="radio"] {
        display: none;
    }

    input[type="radio"]:checked + label {
        background: #D2DFFF;
    }
}

.footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.price {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    display: block;
    text-align: center;

    span {
        font-weight: 800;
    }
}

.confirm {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
}