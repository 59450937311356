@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap');
@import "html";


.App {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    //padding: 20px;
    @media (max-width: 700px) {
        display: block;
        border-bottom: 1px solid #ffffff;
        position: relative;
    }
}

.container {
    max-width: 1170px;
    min-height: 750px;
    max-height: 750px;
    height: 750px;
    width: 100%;
    background: #FFF;
    @media (max-width: 700px) {
        max-height: unset;
        height: 100%;
        overflow-y: scroll;
        border-bottom: 1px solid #ffffff;
        padding-bottom: 200px;
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
    }
}