.tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #F3F3F3;
    padding: 0px 40px;
    width: 100%;
    @media (max-width: 700px) {
        overflow-x: scroll;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: unset
    }
}

.tab{
    padding: 10px 30px;
    text-align: center;
    font: normal normal 600 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    cursor: pointer;
    width: auto;
    &:hover{
        background: #FFD52F;
    }
    @media (max-width: 700px) {
        white-space: nowrap;
        font: normal normal 600 13px/19px Montserrat;
    }
}

.tab_active{
    background: #FFD52F;
    color: black;
}