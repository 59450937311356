.container {
    height: 100%;
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-auto-rows: max-content;
    @media (max-width: 700px) {
        height: auto;
    }
}

.content {
    padding: 20px 50px;
    overflow-y: scroll;
    @media (max-width: 700px) {
        padding: 20px 20px;
        overflow-y: unset;
    }
}

