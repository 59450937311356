.container{
    position: relative;
}
.header {
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}

.placeholder {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #000000;
}

.list {
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    padding: 5px 10px;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    transition: 0.2s ease;
    position: absolute;
    top: 100%;
    max-height: 0px;
    opacity: 0;
    z-index: 3;

}
.list_active{
    max-height: 100px;
    min-height: 100px;
    opacity: 1;
}
.item {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 5px 0px;

    input {
        margin-right: 15px;
        margin-bottom: 0px;
    }
}