.ticket {
    background: #F3F3F3;
    padding: 25px;
    display: grid;
    grid-template-columns: 150px 1fr 210px;
    grid-column-gap: 5px;
    margin-bottom: 10px;

    input[type="number"] {
        padding-right: 100px;
        height: auto;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
        gap: 10px;
    }
}

.icon_ticket {
    display: block;
}

.number_ticket {
    display: block;
    width: 100px;
    margin-top: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #BAC1D4;
    text-align: right;
}

.discounts {
    h3 {
        margin-top: 0px;
        grid-column: -1/1;
    }
}

.container_discount {
    display: grid;
    grid-template-columns: auto 30px;
    position: relative;
}

.discount_description {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.discount_description_popup {
    position: absolute;
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid #e1e1e1;
    top: calc(100% + 5px);
}

.selects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start !important;
    grid-gap: 20px;

    input,
    select {
        height: auto;
        margin-bottom: 0px;
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
}

.price_block {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;

    @media (max-width: 700px) {
        margin-top: 10px;
        display: flex;
        flex-direction: row-reverse;
    }

}

.price {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-top: auto;
    @media (max-width: 700px) {
        margin-right: auto;
    }
}

.delete {
    cursor: pointer;

    svg {
        path {
            transition: 0.2s ease;
        }
    }

    svg:hover {
        path {
            fill: #000000;
        }
    }
}