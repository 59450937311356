::selection {
    color: #000000;
    background: #FFD52F
}

::-moz-selection {
    color: #000000;
    background: #FFD52F
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    background-color: #FFD52F;
    border-radius: 5px;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(6, 190, 225, 0.3);
    outline: 0;
    outline-offset: 0;
    outline-style: none;
    outline-width: 0px !important;
}

html {
    box-sizing: border-box;
    min-width: 320px;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
}

body {
    box-sizing: border-box;
    min-width: 320px;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    margin: 0;
    padding: 0;
    background: #ECECEC;
    overflow-y: hidden;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    overflow: hidden;
}

a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #002C9C;
}

p {
    font: normal normal normal 15px/19px Montserrat;
    letter-spacing: 0px;
    color: #676767;
}

h1 {
    font: normal normal 600 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}

h2 {
    font: normal normal 600 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}

h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}

h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}

strong {
    font-weight: 700;
}

ul {
    margin-left: 0px;
    padding-left: 20px;
    margin-bottom: 25px;
}

ol {
    margin-left: 0px;
    padding-left: 20px;
    margin-bottom: 25px;
}

form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(426px, 1fr));
    grid-gap: 10px;
}

label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    display: block;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"] {
    padding: 10px 16px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    margin-bottom: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;

    &::placeholder {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.5);
    }

    &.error {
        border-color: #ff5c5c;
    }
}

textarea {
    padding: 10px 16px;
    width: 100%;
    min-height: 70px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    margin-bottom: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    resize: unset;

    &::placeholder {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.5);
    }

    &.error {
        border-color: #ff5c5c;
    }
}

textarea,
input {
    outline: none;
    z-index: 1;
}

input[type="time"],
input[type="date"],
input[type="week"],
input[type="month"] {
    padding: 10px 16px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    margin-bottom: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    cursor: pointer;

    &.error {
        border-color: #ff5c5c;
    }
}

input[type="radio"] {
    cursor: pointer;
    position: relative;
    height: 18px;
    width: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    margin: 0px;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: 1px solid #B5B5B5;
    }

    &:checked::before {
        border: 1px solid #FFD52F;
    }

    &:checked::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #FFD52F;
        transform: translate(-50%, -50%);
        visibility: visible;
    }

    & + label {
        display: inline-block;
        margin-left: 20px;
        cursor: pointer;
    }
}

input[type="checkbox"] {
    position: relative;
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    min-width: 18px;
    max-width: 18px;
    width: 18px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0px;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        transform: translate(-50%, -50%);
        border: 1px solid #B5B5B5;
        border-radius: 3px;
    }

    &:checked::before {
        border-color: #FFD52F;
    }

    &:checked::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 18px;
        width: 18px;
        transform: translate(-50%, -50%);
        visibility: visible;
        background-image: url(../images/checkbox.svg);
        background-position: center;
        background-repeat: no-repeat;
    }

    & + label {
        display: inline-block;
        margin-left: 20px;
        cursor: pointer;
    }
}

input[readonly] {
    user-select: none;
    cursor: default;
}

button,
input[type="submit"],
input[type="button"] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    background: #FFD52F;
    border-radius: 3px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    border: 0px;
    min-width: 120px;
    margin: 20px 0px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    min-height: 56px;

    &:hover {
        filter: contrast(1.2)
    }

    img,
    svg {
        display: block;
        margin-right: 18px;
    }
}

button[disabled] {
    cursor: default;
    filter: unset;
    filter: grayscale(1) opacity(0.5);
}

textarea {

    &::placeholder {

    }
}

input[type="color"] {
    all: unset;
    width: 24px;
    height: 28px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

    &.error {
        border-color: #ff5c5c;
    }
}

select {
    padding: 10px 16px !important;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    margin-bottom: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    cursor: pointer;
    min-height: 43px !important;
    height: 43px !important;
}

.error {
    border-color: #ff5c5c !important;

    &:before {
        border-color: #ff5c5c !important;
    }
}

.success {
    border-color: #00bb5c !important;

    &:before {
        border-color: #00bb5c !important;
    }
}